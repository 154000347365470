import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import styles from './styles/mobiledown.module.css';
import u, { ELocalKey, ProjectInfo, teseConstant } from "@/util";
import { PROD_SITE } from "@/const/prod.const";


SwiperCore.use([Autoplay, Pagination]);

const MobileDownload: React.FC = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [responseData, setResponseData] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);


    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() => {
    //     if (!isMobile) {
    //         window.location.href = `pc.html${window.location.search}`;
    //     }
    // }, [isMobile]);

    useEffect(() => {
        const fetchData = async () => {
            const downloadAPI = process.env.REACT_APP_STATIC_DOWNLOAD;
            if (!downloadAPI) {
                console.error('REACT_APP_STATIC_DOWNLOAD is not defined.');
                return;
            }

            try {
                const response = await fetch(downloadAPI);
                const data = await response.json();
                const decrypted = u.decrypt(data.data, data.suffix);
                const parsedData = JSON.parse(decrypted);
                setResponseData(parsedData);

                if (document.getElementById('office') && parsedData?.official_website) {
                    document.getElementById('office')!.innerHTML = parsedData.official_website;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const openLink = (type: string) => {
        if (!responseData) return;

        let urlToGo = '';
        switch (type) {
            case 'android_download1':
            case 'android_download2':
            case 'android_download3':
                if(PROD_SITE === "1"){
                    urlToGo = responseData.android_app_download_1;
                }else if(PROD_SITE === "2"){
                    urlToGo = responseData.android_app_download_2;
                } else{
                    urlToGo = responseData.android_app_download_3;
                }
                break;
            case 'report':
                urlToGo = responseData.feedback;
                break;
            case 'telegram':
                urlToGo = responseData.telegram_group;
                break;
            case 'business':
                urlToGo = responseData.merchant;
                break;
            case 'email':
                urlToGo = 'mailto:'+responseData.official_email;
                break;
            case 'qrcode':
                urlToGo = responseData.official_qr;
                break;
            case 'home':
                urlToGo = responseData.official_website;
                break;
            default:
                urlToGo = responseData.official_website;
        }

        if (!urlToGo.startsWith('http') && type !== 'email') {
            urlToGo = `https://${urlToGo}`;
        }
        window.location.href = urlToGo;
    };

    const copyText = (type: string) => {
        if (!responseData) return;

        const targetUrl =
            responseData[type === 'home' ? 'official_website' : 'official_email'] || 'https://example.com';

        navigator.clipboard.writeText(targetUrl).then(() => {
            alert('Copied to clipboard!');
        });
    };

    const bannerList = [
        { img: "/assets/images/h5/img_slide1.png", alt: "Slide 1" },
        { img: "/assets/images/h5/img_slide2.png", alt: "Slide 2" },
        { img: "/assets/images/h5/img_slide3.png", alt: "Slide 3" }
    ];

    const handleDotClick = (index: number) => {
        const swiperElement = document.querySelector(".swiper") as HTMLElement & { swiper?: any };

        if (swiperElement?.swiper) {
            swiperElement.swiper.slideToLoop(index); // Call Swiper's method
            setActiveIndex(index); // Update state
        }
    };

    return (
        <div className={styles.container}>
            {/* Top Container */}
            <div className={styles.top_container}>
                <Swiper
                    loop
                    autoplay={{ delay: 3000 }}
                    className={styles.swiper}
                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)} // Update active index

                >
                {bannerList.map((x, i) => (
                    <SwiperSlide key={i}>
                        <img 
                            style={{ width: "100%", height: 'auto' }} 
                            alt={x.alt} 
                            src={x.img} 
                        />
                    </SwiperSlide>
                ))}
                </Swiper>

                <div className={styles.paginationContainer}>
    {bannerList?.map((_, index) => (
        <div
            key={index}
            className={`${styles.paginationDot} ${index === activeIndex ? styles.activeDot : ''}`}
            onClick={() => handleDotClick(index)}
        ></div>
    ))}
</div>


                <img src="/assets/images/h5/logo.png" alt="Logo" className={styles.img_logo} />
                <img
                    onClick={() => openLink('android_download1')}
                    src="/assets/images/h5/btn_download.png"
                    alt="Download"
                    className={styles.btn_download}
                />
            </div>

            {/* Center Container */}
            <div className={styles.center_container} >
                <div className={styles.gridcon} style={{paddingTop:'60px', width:'100%'}}>
                {/* <img
                            onClick={() => openLink('business')}
                            src="/assets/images/ic_business.png"
                            alt="Business"
                            width={92}
                            height={66}
                        /> */}
                        <img
                            onClick={() => openLink('telegram')}
                            src="/assets/images/ic_telegram.png"
                            alt="Telegram"
                            width={92}
                            height={66}
                        />
                        <img
                            onClick={() => openLink('report')}
                            src="/assets/images/ic_report.png"
                            alt="Report"
                            width={92}
                            height={66}
                        />
                </div>

                <div className={styles.center_item}>
                    <img src="/assets/images/ic_internet.png" alt="Website" width={40} height={40} />
                        <div className={styles.center_text} onClick={() => openLink('home')}>
                            <div className={styles.center_text_top}>永久网站</div>
                            <div className={styles.center_text_bottom} id="office"></div>
                        </div>
                        <button onClick={() => copyText('home')} className={styles.btn_right}>
                            复制官网
                        </button>
                </div>
                <div className={styles.center_item}>
                <img src="/assets/images/ic_email.png" alt="Email" width={40} height={40} />
                        <div className={styles.center_text} onClick={() => openLink('email')}>
                            <div className={styles.center_text_top}>官方邮箱</div>
                            <div className={styles.center_text_bottom}>发送任意消息获取最新地址</div>
                        </div>
                        <button onClick={() => copyText('email')} className={styles.btn_right}>
                            复制邮箱
                        </button>
                </div>
            </div>

            {/* Footer */}
            <div className={styles.footer}>
            <div className={styles.footer_item}>
            <img
                        onClick={() => openLink('android_download1')}
                        src="/assets/images/h5/down_android1.png"
                        alt="Download 1"
                        style={{marginBottom:'15px'}}
                    />
                    <img
                        onClick={() => openLink('android_download2')}
                        src="/assets/images/h5/down_android2.png"
                        alt="Download 2"
                        style={{marginBottom:'15px'}}

                    />
                    <img
                        onClick={() => openLink('android_download3')}
                        src="/assets/images/h5/down_android3.png"
                        alt="Download 3"
                        style={{marginBottom:'15px'}}

                    />
            </div>
            </div>


        </div>
    );
};

export default MobileDownload;
