import useModal, { EModalTypes, IModal } from "@/hooks/useModal";
import { FC, useEffect } from "react";
import styles from "@/components/styles/modal.module.css"
import noticeModal from "@/components/styles/noticeModal.module.css"
import vipModalStyles from "@/components/styles/vipModal.module.css"
import Img from "../img";
import { appendHost } from "@/const/append";

import { Link } from "react-router-dom";
import Button from "../button";
import u, { EVIPtype } from "@/util";
import useUser from "@/hooks/useUser";

const wh = { height: "83%", width: "100%" }

const VipModal: FC<IModal> = () => {
    const [modalData, closeModal] = useModal();
    const [userInfo] = useUser();

    const delMd = (id: any) => {
        const element = document?.getElementById(id);

        if (element !== null) {
            // element.remove();
            element.style.display = "none";
        }
    }

    const vipCategory = () => {
        const currentTime = Math.floor(Date.now() / 1000);

        if (userInfo.vip_level > 0 && userInfo.is_vip === '2') {
            console.log('return permanent vip')
            return "PERMANENT_VIP"
        }

        if (userInfo.vip_level > 0 && userInfo.is_vip === '1' && userInfo.val_time > currentTime) {
            console.log('return normal vip')
            return "NORMAL_VIP"
        }

        if (userInfo.vip_level === '0' && userInfo.is_vip === '0' && userInfo.val_time === '0') {
            console.log('return normal user')
            return "NORMAL_USER"
        }
    }

    const vipStatus = vipCategory();
    console.log('show vipStatus', vipStatus)

    const getBackgroundImage = () => {
        switch (vipStatus) {
            case "PERMANENT_VIP":
                return "/images/login/permanentvip2.png";
            case "NORMAL_VIP":
                return "/images/login/normalvip2.png";
            case "NORMAL_USER":
            default:
                return "/images/login/normaluser2.png";
        }
    };

    const routeToTopic = () => {
        return u.open("/topic")
    }

    console.log('show userInfo', userInfo)

    return (
        modalData?.show && modalData?.id === EModalTypes.VIP &&
        <>

            <div className={styles.common}>
                <div className={`${styles.body} vip_modal`} style={{background:'none'}}>
                <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}}>
                <div
    onClick={() => {
        if (vipStatus === "PERMANENT_VIP" || vipStatus === "NORMAL_VIP") {
            u.formUser(EVIPtype.VIP, modalData?.data?.data?.data?.data);
        } else if (vipStatus === "NORMAL_USER") {
            u.formUser(EVIPtype.BUY, modalData?.data?.data?.data?.data);
        }
    }}
    style={{cursor:'pointer'}}
>
<Img cls="vip_img" src={appendHost(getBackgroundImage())} width={380} height={380} />

</div>
<div onClick={() => {
                        u.formUser(EVIPtype.BUY, modalData?.data?.data?.data?.data)
                    }} className={styles.close} style={{top:'30px'}}>X</div>
<div className="vip_expired_text">
{modalData?.data?.data?.time ? <span>{`${modalData?.data?.data?.time || ''} `}</span> : <br />}

</div>
            </div>

                </div>
            </div>
            {(modalData?.data.data.notice || (Array.isArray(modalData?.data.data.notice) && modalData?.data.data.notice.length > 0)) && modalData?.data.data.notice.map((x: any, i: any) => {
                return <div className={styles.common} id={`notice_modal` + i}>
                    <div className={`${styles.body} notice_modal`}
                        onClick={() => {
                            delMd(`notice_modal` + i)
                        }}>
                        <div className={styles.close}>X</div>
                        <div className={`${styles.content} ${noticeModal.content}`}
                            onClick={() => {
                                routeToTopic()
                            }}>
                            {/* <div className={`${styles.mid_title} mt10`} > {x.title}</div> */}
                            {/* <div className={`${styles.content_inner_title} `} style={{paddingLeft: '10px', paddingRight: '10px'}} dangerouslySetInnerHTML={{ __html: x?.content }}></div> */}
                            <Img encryptUrl={modalData?.data.data.imgs[0]} cls="" src={modalData?.data.data.imgs[0]} style={{ objectFit: 'contain', width: "0", height: "0" }} >
                                {(_decryptUrl: any) => {
                                    return <>
                                        {_decryptUrl && _decryptUrl.includes("data") &&
                                            <Img cls="lazy" encryptUrl={modalData?.data.data.imgs[0]} style={{ objectFit: 'contain', width: "100%", height: "100%" }} src={_decryptUrl} />
                                        }
                                    </>
                                }}
                            </Img>
                        </div>
                    </div>
                </div>
            })}
        </>
    )
}
export default VipModal;