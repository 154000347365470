import Back from "@/components/back"
import Img from "@/components/img"
import CategoryHead from "@/components/categoryHead"
import useAxios from "@/hooks/useAxios"
import u from "@/util"
import { useParams } from "react-router-dom"
import React, { FC, useCallback, useEffect, useState } from "react"
import styles from "./styles/chapter.module.css"
import Footer from "@/components/footer"
import BackBlack from "@/components/backBlack"
import Pagination from "@/components/pagination"
import Empty from "@/components/empty"
import useConfBase from "@/hooks/useConfBase"

const ChapterIndex: FC = () => {
    const { channel } = useParams()
    return (
        <CategoryHead showCategory>
            {channel === "tupian" && <ChapterTupian />}
            {channel === "xiaoshuo" && <ChapterNovel />}
            {channel === "yousheng" && <ChapterYousheng />}
            {channel === "meinv" && <ChapterMeinv />}
        </CategoryHead>
    )
}
export default ChapterIndex;

const ChapterTupian: FC = () => {
    const { id } = useParams()
    const [config] = useConfBase()
    const { req } = useAxios("tupian/detail", "post");
    const [img, setImg] = useState<string[]>([])

    const fetchDetails = useCallback(async () => {
        if (!id) return;
        const res = await req({ id: id })
        if (res?.data) {
            const isTrue = Array.isArray(res?.data?.info?.content);
            if (isTrue) {
                const reqs = res?.data?.info?.content.map((x: string) => fetch(`${config.image_url}/${x}.txt`).then((res) => res.text()))
                await Promise.all(reqs).then((res) => {
                    const _res = res.map((x) => x.indexOf("data")>= 0 ? x : u.imgDecrypt(x))
                    setImg(_res)
                });
            }
        }
    }, [id])

    useEffect(() => {
        fetchDetails()
    }, [])
    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>图片详情</Back>
                <div className={`grid ${styles.chap_img}`}>
                    {Array.isArray(img) && img.map((x) => {
                        return <Img style={{ width: "100%", height: "100%", borderRadius: "10px" }} src={x} />
                    })}
                </div>
                {img.length === 0 && <Empty />}
            </div>
            <Footer />
        </div>
    )
}

const ChapterNovel: FC = () => {
    const { req } = useAxios("chapter/base", 'post')
    const { channel, id } = useParams()
    const [data, setData] = useState<any>({})

    const fetchDetails = useCallback(async (page?: any) => {
        if (!id) return;
        const res = await req({ id: id, channel: channel, page: page ?? 1 })
        if (res) {
            setData(res?.data?.info)
        }
    }, [channel, id])

    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <BackBlack row={data} title={"小说章节"}></BackBlack>
                <div className="mt10 grid gap10">
                    <div className={`text_center ${styles.tt}`}>MAOMIAV精选图册，满足猫友们的喜好，每日更新</div>
                    <div className="fl">
                        小说的简介：<div>{data?.info?.description || "-"}</div>
                    </div>
                    <div>
                        <div className={styles.mm}>
                            {data?.chapters?.data.map((x: any, i: number) => {
                                return <div key={i} className={styles.item} onClick={() => window.open(`/s/detail/${channel}/${x.id}`)}>
                                    {x.title}
                                </div>
                            })}
                        </div>
                        <Pagination lastPg={data?.chapters?.last_page} total={data?.chapters?.total} changeHandler={ async (e) => {
                            await fetchDetails(e.current)
                        }} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const ChapterYousheng: FC = () => {
    const { req } = useAxios("chapter/base", 'post')

    const [info, setInfo] = useState<any>({})
    const [chapter, setChapter] = useState<any>({})
    const { channel, id } = useParams()
    const fetchDetails = useCallback(async (p?: any) => {
        if (!id) return;
        const res = await req({ channel: "yousheng", id: id, page: p || 1 })
        if (res?.data) {
            setInfo(res?.data?.info)
            setChapter(res?.data?.info?.chapters)
        }
    }, [id])

    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <div>
                    <div className="text_center mb20">MAOMIAV精选有声小说，满足猫友们的喜好，每日更新</div>
                    <div>
                        {Array.isArray(chapter?.data) && chapter?.data?.length > 0 && chapter?.data.map((x: any) => {
                            return <div className={styles.ys_item} onClick={() => window.open(`/s/detail/${channel}/${x.id}`)}>
                                <div>{x.title}</div>
                            </div>
                        })}
                        {chapter?.data?.length === 0 && <Empty />}
                    </div>

                    <Pagination lastPg={chapter?.last_page} perPage={20} total={chapter?.total} changeHandler={ async (e) => {
                        await fetchDetails(e.current);
                    }} />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

const ChapterMeinv: FC = () => {
    const { id } = useParams()
    const [config] = useConfBase()
    const { req } = useAxios("meinv/detail", "post");
    const [img, setImg] = useState<string[]>([])
    const [data, setData] = useState<any>({})
    const fetchDetails = useCallback(async () => {
        if (!id) return;
        const res = await req({ id: id, channel: "meinv"})
        if (res?.data) {
            setData(res?.data?.info)
            const isTrue = Array.isArray(res?.data?.info?.content);
            if (isTrue) {
                const reqs = res?.data?.info?.content.map((x: string) => fetch(`${config.image_url}/${x}.txt`).then((res) => res.text()))
                await Promise.all(reqs).then((res) => {
                    const _res = res.map((x) => x.indexOf("data") >= 0 ? x : u.imgDecrypt(x))
                    setImg(_res)
                });
            }
        }
    }, [id])

    useEffect(() => {
        fetchDetails()
    }, [])
    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
            <BackBlack channel="detailmeinv" row={data} title={"美女详情"}></BackBlack>
                <div className={`grid mt10 ${styles.chap_img}`}>
                    {Array.isArray(img) && img.map((x) => {
                        return <Img style={{ width: "100%", height: "100%", borderRadius: "10px" }} src={x} />
                    })}
                </div>
                {img.length === 0 && <Empty />}
            </div>
            <Footer />
        </div>
    )
}