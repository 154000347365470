import useConfBase from "@/hooks/useConfBase"
import { FC } from "react"
import Img from "./img";
import styles from "./styles/parentCategory.module.css"

interface IParentCategory {
    list: any[];
    handleClick: any;
    isSelected: any
}

const ParentCategory: FC<IParentCategory> = ({ list, handleClick, isSelected }) => {
    const [config] = useConfBase()
    return (
        <div>
            <div className={styles.p}>
                    {Array.isArray(list) && list.map((x: any, i: number) => {
                        return <div key={i} onClick={() => handleClick({ ...x, i })}>
                            <Img width={60} height={60} src={`${x.icon}`} />
                            <div className={isSelected == x?.jump_name ? styles.clr : styles.dls}>{x.name}</div>
                        </div>
                    })}
                </div>
        </div>
    )
}
export default ParentCategory