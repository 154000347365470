import { FC, useCallback, useMemo, useState } from "react";
import LoginWrapper from "@/components/loginWrapper";
import registerStyles from "./styles/register.module.css"
import LoginSwitchBar from "@/components/loginSwitchBar";
import styles from "./styles/login.module.css"
import Input from "@/components/input";
import Img from "@/components/img";
import { appendHost } from "@/const/append";

import Button from "@/components/button";
import { Link } from "react-router-dom";
import useAxios from "@/hooks/useAxios";
// import { ILoginByUsername } from "@/const/api.const";
import useValidation from "@/hooks/useValidation";
import { vID } from "@/const/validate.const";
import useFlipFlop from "@/hooks/useFlipFlop";
// import Modal from "@/components/modal";
import useUpdateForm from "@/hooks/useUpdateForm";
import Select from "@/components/select";
import { areaCodes } from "@/const/areaCode";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import VipModal from "@/components/modal/vipModal";
import useUser from "@/hooks/useUser";
import u, { ELocalKey } from "@/util";
import ChargeModal from "@/components/modal/chargeModal";
import useConfBase from "@/hooks/useConfBase";
import useFocus from "@/hooks/useFocus";
// import { PROD_SITE } from "@/const/prod.const";
import BuyTopicModal from "@/components/modal/buyTopicModal";
import NoticeModal from "@/components/modal/noticeModal";

const Login: FC = () => {
    const [userInfo, setUserInfo] = useUser()
    const [config, updateConfBase] = useConfBase()
    const [encryptUrls, setEncryptUrls] = useState<string[]>([])
    const [selectedBar, setSelectedBar] = useState<number>(1);
    const [formData, setFormData] = useState<any>({
        username: "",
        password: "",
        phone_code: "+86",
        phone: "",
    })
    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")
    const { req } = useAxios("user/loginByUsername", 'post', false, true);
    const { req: reqNotice } = useAxios("notice/list", 'post',);
    const { req: req2 } = useAxios("user/login", 'post', false, true);
    const { req: req3 } = useAxios("index/countlogin", "post", true);

    const updateForm = useUpdateForm(setFormData);

    const validateCondition = useMemo(() => {
        if (selectedBar == 1) {
            return {
                username: [vID.USERNAME_EMPTY, vID.MIN_TEXT_6_12],
                password: [vID.PWD_EMPTY, vID.MIN_TEXT_6_20],
            }
        }
        return {
            phone_code: [vID.PHONE_AREA_CODE],
            password: [vID.PWD_EMPTY, vID.MIN_TEXT_6_20],
            phone: [vID.TRUE_FORMAT_PHONE],
        }
    }, [selectedBar]) as any

    const [isValidSubmit, msgStatus] = useValidation(formData, validateCondition, "password");

    const hitStats = useCallback(async (status: boolean | 2) => {
        const name = status === 2 ? "contact" : status ? 'success' : 'fail'
        await req3({ ...u.getStatsParams(), category: "login", name })
    }, [])

    const login = useCallback(async () => {
        if (!isValidSubmit) return;
        flipHander(1)
        const _fn = selectedBar == 1 ? () => req(formData) : () => req2({ phone_code: formData.phone_code, phone: formData.phone, password: formData.password });
        const res = await _fn();
        const tme = res?.data?.data?.is_vip == 1 ? u.toFmt(parseInt(res?.data?.data?.val_time) * 1000) : '';

        console.log('userinfo -', res)
        await hitStats(res && res?._raw?.status === 200)

        const buyTopicId = localStorage.getItem(ELocalKey._BT);

        console.log(buyTopicId);
        if (res?.code === 0) {
            try{
                if(res?.data){
                    console.log(res?.data?.data?.username + '--- be4 send ---' , JSON.stringify(res?.data))
                    if(res?.data?.data && res?.data?.data?.username){
                        u.sendVIPLogDomain(res.data.username, ' login data - ' + JSON.stringify(res?.data));
                    }
                    console.log(res?.data?.data?.username + '--- aft send ---' , JSON.stringify(res?.data))
                }
            }catch(e){}

            let resultNotice: any = null;
            let imgUrls = [];
            // if user is vip
            if(res?.data?.data?.is_vip !== "0"){
                resultNotice = await reqNotice({site: '1', type:5, is_mobile: 0});
                console.log('notice---' , resultNotice)
                try{
                    if(resultNotice && resultNotice?.data?.notice && Array.isArray(resultNotice?.data?.notice) && resultNotice?.data?.notice.length === 0){
                        // no show
                    }else{
                        if(resultNotice && resultNotice?.data?.notice){
                            console.log('notice result---' , resultNotice?.data?.notice)
                            if(resultNotice?.data?.notice && resultNotice?.data?.notice.length > 0){
                                if(resultNotice?.data?.notice[0]?.pic_1){
                                    imgUrls.push(`${resultNotice?.data?.notice[0]?.pic_1}`.replace('.txt',''));
                                    // imgUrls.push(`${config?.image_url}/${resultNotice?.data?.notice[0]?.pic_1}.txt`);
                                }
                            }
                            setEncryptUrls(imgUrls)
                        }
                    }
                } catch(err) { 
                    console.log('notice err---' , err)
                }   
            }

            if(buyTopicId){
                modalSubject$.next({
                    id: EModalTypes.BUYTOPIC,
                    data: { price: buyTopicId.substring(buyTopicId.lastIndexOf('-')+1, buyTopicId.length) },
                    event: () => flipHander(0)
                })
            }else{
                modalSubject$.next({
                        id: EModalTypes.VIP,
                        data: { time: tme, data: res.data, notice: resultNotice?.data?.notice ?? null, imgs: imgUrls },
                        event: () => flipHander(0)
                    })
                    console.log('immmgmg , ',imgUrls)
            }
       
            return setUserInfo(res.data)
        } else if (res?.code === 5004) {
            modalSubject$.next({
                id: EModalTypes.CHARGE,
                data: res.data,
                event: () => flipHander(0)
            })

            return setUserInfo(res.data)
        } else {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res?.msg || "请稍后再试！",
                btn: true,
                kefu: res?.msg ? false : true,
                event: () => flipHander(0)
            })
        }

    }, [isValidSubmit, flipHander, selectedBar, formData, config, updateConfBase])

    const changeLoder = useCallback((x: any) => {
        return {
            name: `${x.value}${x.name}`,
            value: x.value
        }
    }, [])

    const selectOnchange = useCallback((val: any) => {
        const _val = val.split("-");
        updateForm({
            phone_code: _val[0],
        })
    }, [updateForm])

    const handleSwtich = useCallback((val: any) => {
        setSelectedBar(val);
        setFormData({
            username: "",
            password: "",
            phone_code: "",
            phone: "",
        })
        flipHander(0)
    }, [flipHander])

    useFocus(login)
    return (
        <>
            <div className={`grid ${registerStyles.register} bgfa`}>
                <div className={`grid ${registerStyles.form} mauto`}>
                    <div className={registerStyles.header}>
                        <LoginSwitchBar selectedCls={styles.selected} unSelectedCls={styles.unSelectedCls} value={selectedBar} event={handleSwtich} list={list} />
                    </div>
                    <div className={`grid justify_center`}>
                        <div className={`grid ${styles.inner3}`}>
                            <div>
                                {selectedBar === 1 && <div>
                                    <Input errMsg={msgStatus[0]} prefix={<Img src={appendHost("/images/icon/icon_user.png")} width={20} height={20} />} value={formData.username} onChange={(e) => updateForm({ ...formData, "username": e })} line placeholder="输入用户名" />
                                </div>}
                                {selectedBar === 2 && <div>
                                    <Input errMsg={[msgStatus[0], msgStatus[2]]} onChange={(v) => updateForm({ ...formData, "phone": v })} prefix={
                                        <div className={styles.phone_select}>
                                            <Img src={appendHost("/images/icon/icon_user.png")} width={20} height={20} />
                                            <Select value={formData.phone_code} list={areaCodes} loader={changeLoder} onChange={selectOnchange} />
                                        </div>} line placeholder="输入手机号码" />
                                </div>}
                            </div>

                            <div>
                                <Input errMsg={msgStatus[1]} prefix={<Img src={appendHost("/images/icon/icon_password.png")} width={20} height={20} />} value={formData.password} onChange={(e) => updateForm({ ...formData, "password": e })} line type="password" placeholder="密码" />
                            </div>

                            <div className={styles.forgot}>
                                <Link className="d_none" to="/forget">忘记密码</Link> / <span onClick={async () => {
                                    await hitStats(2);
                                    return u.kefuOpen()
                                }} className={`${registerStyles.click_register} fw500 d_none`}>登录异常</span>
                            </div>
                        </div>

                        <div className="t_center">
                            <Button event={login} cls={`btn_sty1 ${u.themeBtnCls()}`}>{flipText}</Button>
                            <div className={registerStyles.btm}>
                                没有账号？  <Link className={`${registerStyles.click_register} fw500 d_none`} to="/register">点击注册</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <LoginWrapper />
            </div>
            {/* <Modal /> */}
            <VipModal />
            <BuyTopicModal />
            <ChargeModal />
            <NoticeModal/>
        </>
    )
}

export default Login;

const list = [
    {
        name: "用户登录",
        id: 1
    },
    {
        name: "手机登录",
        id: 2
    }
];